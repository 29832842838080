import { Dictionary, Results } from 'types';
import { QueryClient } from 'react-query';
import { dictionary as dictionaryAPI } from 'api';
import { DictionaryStoreType } from 'context/Dictionary';
import { defaultOptions, getQueries } from 'utils';
import { Locale } from 'types';
import { defaultDictionaries } from 'context/Dictionary/DictionaryContext';

export const dictionary = {
  /**
   * This will fetch dictionary namespaces.
   *
   * @example
   *
   * await getNamespacesData(["foo"], "ro");
   *
   * @param ns - the namespaces to fetch
   * @param locale - the locale to fetch the dictionaries.
   */
  getNamespacesData: async (ns: string[], locale: Locale): Promise<DictionaryStoreType> => {
    const queryClient = new QueryClient(defaultOptions);

    await queryClient.prefetchQuery<Results<Dictionary>>(['data', { text_id__in: ns }], (props) =>
      dictionaryAPI.getList(props, { 'Accept-Language': locale }),
    );

    const { data } = getQueries(queryClient);

    const initialStore: DictionaryStoreType = { ...defaultDictionaries };
    return (
      (data?.results || []).reduce((acc: DictionaryStoreType, curr: Dictionary) => {
        const store: DictionaryStoreType = { ...acc };
        store[locale] = { ...store[locale], [curr.text_id]: {} };

        try {
          store[locale][curr.text_id] = JSON.parse(curr.i18n[locale]);
        } catch (e) {
          // tslint:disable-next-line: no-console
          console.warn('Failed to parse namespace:', curr.text_id);
        }

        return store;
      }, initialStore) || undefined
    );
  },
};
