import { useContext, useEffect } from 'react';
import { QueryClient } from 'react-query';
import { NextPage } from 'next';
import { articles } from 'api';
import withTranslation from 'context/Dictionary/withTranslation';
import { defaultOptions, getQueries, cacheTime } from 'utils';
import { Article, ArticleTags, Locale } from 'types';

import { StoreContext } from './index';
import { isLocale } from '../Dictionary';

interface WithStoreProps {
  menu?: Article[];
  pageProps: any;
}

function withStore<T = {}>() {
  return (WrappedComponent: NextPage<T>) => {
    const WithStore: NextPage<WithStoreProps> = ({ menu, pageProps }) => {
      const { isDeploy, setState } = useContext(StoreContext);

      useEffect(() => {
        if (typeof menu !== 'undefined') {
          setState({ menu });
        } else if (!isDeploy) {
          setState({ isDeploy: true });
        }
      }, [menu]);

      return <WrappedComponent {...pageProps} />;
    };

    WithStore.getInitialProps = async (ctx) => {
      const locale = `${ctx.query.lang}`;

      let props: WithStoreProps = {
        pageProps: {},
      };

      if (WrappedComponent.getInitialProps) {
        props.pageProps = await WrappedComponent.getInitialProps(ctx);
      }

      try {
        const queryClient = new QueryClient(defaultOptions);

        await queryClient.prefetchQuery(
          ['data', { page_size: 100, tags: ArticleTags.MENU, has_parents: false }],
          (props) =>
            articles.getList(props, { 'Accept-Language': isLocale(locale) ? locale : Locale.RO }),
          { cacheTime },
        );

        const { data } = getQueries(queryClient);

        props.menu = data?.results;
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable: no-console
          console.error(e);
          // tslint:enable: no-console
        }
      }

      return props;
    };

    WithStore.displayName = `WithStore(${WrappedComponent.displayName})`;

    return withTranslation(['common', 'footer', 'header', 'pages', 'contact'])(WithStore);
  };
}

export default withStore;
