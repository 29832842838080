import { useContext, useEffect } from 'react';
import { NextPage } from 'next';
import { dictionary } from 'libs/dictionary';
import DictionaryContext, { defaultDictionaries } from './DictionaryContext';
import { DictionaryStoreType, isLocale } from './index';
import { ActionType } from './utils';
import { Locale } from 'types';

interface WithTranslationProps {
  dictionary: DictionaryStoreType;
  pageProps: any;
}

function withTranslation<T = {}>(ns: string[]) {
  return (WrappedComponent: NextPage<T>) => {
    const WithTranslation: NextPage<WithTranslationProps> = (props) => {
      const { dispatch } = useContext(DictionaryContext);

      useEffect(() => {
        dispatch({ type: ActionType.ADD, dictionary: props.dictionary });
      }, [props.dictionary]);

      return <WrappedComponent {...props.pageProps} />;
    };

    WithTranslation.getInitialProps = async (ctx) => {
      const props: WithTranslationProps = {
        pageProps: {},
        dictionary: { ...defaultDictionaries },
      };

      const locale = `${ctx.query.lang}`;

      if (WrappedComponent.getInitialProps) {
        props.pageProps = await WrappedComponent.getInitialProps(ctx);
      }

      try {
        props.dictionary = await dictionary.getNamespacesData(
          ns,
          isLocale(locale) ? locale : Locale.RO,
        );
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          // tslint:disable: no-console
          console.warn('Could not fetch namespaces:', ns);
          console.warn('With locale', locale);
          console.error(e);
          // tslint:enable: no-console
        }
      }

      return props;
    };

    WithTranslation.displayName = `WithTranslation(${WrappedComponent.displayName})`;

    return WithTranslation;
  };
}

export default withTranslation;
